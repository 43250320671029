import { ConfigService } from '@nextgen-web-framework/core';
import livePreviewApi from './livePreviewApi';

export const attachLivePreviewEvent = (cb: (payload: any, contentTypeId: string) => void) => {
  const configs = ConfigService.getConfigs();
  // Don't post message if page is not opened in iframe
  if (window?.self !== window?.top) {
    window?.parent?.postMessage(
      {
        type: 'init',
        from: 'live-preview',
        data: {
          config: {
            shouldReload: false,
            href: window.location.href,
          },
        },
      },
      `${configs.NEXT_PUBLIC_CONTENT_STACK_LIVE_PREVIEW_DOMAIN}`,
    );

    window?.addEventListener('message', (e) => onMessage(e, cb));
  }
};

export function removeLivePreviewListener(cb: (action: any, payload: any) => void) {
  window?.removeEventListener('message', (e) => onMessage(e, cb));
}

let contentTypeUid = '';
let entryUid = '';

export const onMessage = async (messageEvent: MessageEvent<any>, cb: (payload: any, contentTypeId: string) => void) => {
  const { data, from, type } = messageEvent.data;

  if (from === 'live-preview') {
    if (data?.hasOwnProperty('entryUid') && type === 'init-ack') {
      if (entryUid !== data['entryUid']) {
        entryUid = data['entryUid'];
      }
      if (contentTypeUid !== data['contentTypeUid']) {
        contentTypeUid = data['contentTypeUid'];
      }
    }
    if (data?.hasOwnProperty('hash') && type === 'client-data-send') {
      const hash = data['hash'];
      const previewData = await livePreviewApi(entryUid, hash, contentTypeUid);
      cb(previewData, contentTypeUid);
    }
  }
};
