const COMPONENT_CONTENT_TYPE_ALIAS_MAPPING: { [key: string]: string } = {
  hero_banner: 'hero_banner',
  mega_menu_navigation: 'mega_menu',
  footer: 'footer',
  about_amway_navigation: 'about_amway_navigation',
  hero_component: 'hero_component',
  rich_text_component: 'rich_text_component',
  quick_links_design_one: 'quick_links_design_one',
  quick_links_design_two: 'quick_links_design_two',
  home_banner: 'home_banner',
  amway_highlights: 'amway_highlights',
  steps_to_business_owner: 'steps_to_business_owner',
  download_links: 'download_links',
  amway_business_links: 'amway_business_links',
  banner_rich_text_component: 'banner_rich_text_component',
  amway_promises: 'our_promises',
  meet_our_scientists: 'meet_our_scientists',
  featured_cards: 'featured_cards',
  notification: 'notification',
  product_carousel: 'product_carousal',
  a_point_product_carousel: ' a_point_product_carousel',
  me_panel: 'me_panel',
  video_banner: 'video_banner',
  product_carousal_banner: 'product_carousal_banner',
  featured_articles_carousal: 'featured_articles_carousal',
};

export default COMPONENT_CONTENT_TYPE_ALIAS_MAPPING;
