import { cmsApi } from '@commerce-webcomponents/sdk';
import PAGE_CONTENT_TYPE_ALIAS_MAPPING from './pageContentTypeAliasMapping';
import COMPONENT_CONTENT_TYPE_ALIAS_MAPPING from './componentContentTypeAliasMapping';
import { CMSTransformerDataType } from 'types/common';

const livePreviewApi = async (entryId: string, hash: string, contentType: string): Promise<any> => {
  let entryData = {};
  try {
    const resolvedContentType = resolveContentTypeAlias(contentType);
    const componentData: CMSTransformerDataType = await cmsApi.fetchLivePreview({
      entryId,
      hash,
      contentType: resolvedContentType,
    });
    if (contentType in COMPONENT_CONTENT_TYPE_ALIAS_MAPPING) {
      entryData = componentData?.data?.length ? convertComponentEntryToPagePayload(componentData, contentType) : {};
    } else {
      entryData = componentData;
    }
  } catch (err) {
    entryData = {};
  }
  return entryData;
};

export const resolveContentTypeAlias = (contentTypeUid: string) => {
  let resolvedAlias = contentTypeUid;
  if (contentTypeUid in PAGE_CONTENT_TYPE_ALIAS_MAPPING) {
    resolvedAlias = PAGE_CONTENT_TYPE_ALIAS_MAPPING[contentTypeUid];
  }
  if (contentTypeUid in COMPONENT_CONTENT_TYPE_ALIAS_MAPPING) {
    resolvedAlias = COMPONENT_CONTENT_TYPE_ALIAS_MAPPING[contentTypeUid];
  }
  return resolvedAlias;
};

export const convertComponentEntryToPagePayload = (componentData: any, contentType: string) => {
  return {
    data: [
      {
        entry: {
          content_section: [
            {
              [contentType]: {
                [contentType]: [{ ...componentData?.data[0].entry }],
              },
            },
          ],
          body_section: [
            {
              [contentType]: {
                [contentType]: [{ ...componentData?.data[0].entry }],
              },
            },
          ],
        },
      },
    ],
  };
};

export default livePreviewApi;
