const PAGE_CONTENT_TYPE_ALIAS_MAPPING: { [key: string]: string } = {
  '404_page': '404',
  hero_banner: 'banner',
  homepage: 'home',
  about_amway_pages: 'about_amway',
  about_amway_news_page: 'about_amway_news',
  about_amway_awards_page: 'about_amway_awards',
  about_amway_how_amway_business_works: 'about_amway_business',
  about_amway_manufacturing_page: 'about_amway_manufacturing',
  about_amway_brands_page: 'about_amway_brands',
  about_amway_journey_page: 'about_amway_journey',
  shop_by_page: 'shop_by',
  faq_page: 'faq',
  my_shop_onboarding_page: 'my_shop_onboarding',
  payment_methods: 'payment_methods',
  about_amway_news_article: 'news',
  me_panel: 'me_panel',
  header: 'header',
  footer: 'footer',
  subscription_landing_page: 'subscription_landing',
  offers_page: 'offers',
  my_shop_create_edit_page: 'my_shop_create_edit',
  my_shop_landing_page: 'my_shop_landing',
  subscription_plan_details_page: 'subscription_plan_details',
  subscription_plan: 'subscription_plan',
  profile_images: 'profile_images',
  find_out_more: 'find_out_more',
  sharebar_email: 'sharebar_email',
  brand_page: 'brand',
  sitemap_page: 'sitemap',
  redirection_items: 'redirections',
  rewrite_items: 'rewrites',
  thank_you_messages: 'thank_you',
  progressive_profile_stepper: 'progressive_profile_stepper',
  account_page: 'account',
  cart_page: 'cart',
  sub_cart_page: 'sub_cart',
  category_page: 'category',
  complete_your_profile_page: 'complete_your_profile',
  group_order_page: 'group_order',
  my_subscriptions_page: 'my_subscriptions',
  myshop_page: 'myshop',
  order_confirmation_page: 'order_confirmation',
  order_details_page: 'order_details',
  order_history_page: 'order_history',
  product_page: 'product',
  search_page: 'search',
  signup_find_out_more_page: 'signup_find_out_more',
  subscription_checkout_page: 'subscription_checkout',
  subscription_confirmation_page: 'subscription_confirmation',
  subscription_plan_page: 'subscription_plan_page',
  subscription_profile_page: 'subscription_profile',
  login_page: 'login',
  global_search: 'global_search',
  account_dashboard: 'account_dashboard',
};

export default PAGE_CONTENT_TYPE_ALIAS_MAPPING;
