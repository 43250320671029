import Head from 'next/head';
import { useNextgenRouter } from '@nextgen-web-framework/all';
import { ConfigService } from '@nextgen-web-framework/core';
import { CMSTransformerDataType } from '../../types/common';
import { i18n } from '../../i18n.config';
import { SEOComponentProps, SeoBaseUrl } from './types/propsTypes';

const getBaseUrl = ({ pathname, baseUrl, locale, userType }: SeoBaseUrl) => {
  let routePath = pathname?.replace(`/${locale}`, '');
  if (userType) {
    routePath = routePath?.replace(`/${userType}`, '');
  }
  return i18n?.defaultLocale === locale ? `${baseUrl}${routePath}` : `${baseUrl}/${locale}${routePath}`;
};

const SEOComponent = (props: CMSTransformerDataType) => {
  const configs = ConfigService.getConfigs();
  const baseUrl = (configs.DEFAULT_HOST_URL || '') as string;
  const router = useNextgenRouter();
  const {
    title,
    description,
    open_graph,
    twitter,
    canonical_tag: canonicalUrl,
    image,
    userType,
    robots_meta_data,
  } = props || {};

  const canonicalTagUrl = canonicalUrl?.startsWith('/')
    ? getBaseUrl({
        baseUrl,
        locale: router?.params?.locale as string,
        pathname: canonicalUrl || router.pathname,
        userType,
      })
    : canonicalUrl;

  const seoData: SEOComponentProps = {
    openGraph: {
      title: open_graph?.title || title,
      description: open_graph?.description || description,
      url: getBaseUrl({
        baseUrl,
        locale: router?.params?.locale as string,
        pathname: open_graph?.url || router.pathname,
        userType,
      }),
      image: open_graph?.image?.url || image?.url,
      type: open_graph?.type ?? '',
    },
    twitter: {
      title: twitter?.title || title,
      description: twitter?.description || description,
      image: open_graph?.image?.url || image?.url,
    },
    image: image?.url,
    canonicalTag: canonicalTagUrl,
    title,
    siteName: configs.NEXT_PUBLIC_SITE_NAME as string,
    description,
  };
  const robotsMetaData = () => {
    let NO_INDEX = process.env.NEXT_NOINDEX_ENABLED;
    let NO_FOLLOW = process.env.NEXT_NOFOLLOW_ENABLED;
    let robotMetaData = '';
    if (robots_meta_data?.enable) {
      NO_INDEX = robots_meta_data.no_index;
      NO_FOLLOW = robots_meta_data.no_follow;
    }
    if (NO_INDEX && NO_FOLLOW) {
      robotMetaData = 'noindex, nofollow';
    } else if (NO_INDEX) {
      robotMetaData = 'noindex';
    } else if (NO_FOLLOW) {
      robotMetaData = 'nofollow';
    }
    return robotMetaData !== '' ? <meta name="robots" content={`${robotMetaData}`} /> : '';
  };
  const generateHreflangTags = () =>
    i18n?.locales?.map((locale: string) => {
      let routePath = router.pathname?.replace(`/${router.params?.locale}`, '');
      if (userType) {
        routePath = routePath?.replace(`/${userType}`, '');
      }
      const href = i18n?.defaultLocale === locale ? `${baseUrl}${routePath}` : `${baseUrl}/${locale}${routePath}`;
      return <link key={locale} rel="alternate" hrefLang={locale} href={href} />;
    });

  return (
    <Head>
      <title>{seoData?.title}</title>
      {generateHreflangTags()}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {robotsMetaData()}
      <meta name="description" content={seoData?.description} />
      <link rel="canonical" href={seoData?.canonicalTag} />
      <link rel="image_src" href={seoData?.image} />
      <meta property="og:type" content={seoData?.openGraph?.type} />
      <meta property="og:url" content={seoData?.openGraph?.url} />
      <meta property="og:site_name" content={seoData?.siteName} />
      <meta property="og:title" content={seoData?.openGraph?.title} />
      <meta property="og:description" content={seoData?.openGraph?.description} />
      <meta property="og:image" content={seoData?.openGraph?.image} />
      {/* 180x110 Image for Linkedin */}
      <meta property="og:image:width" content="180" />
      <meta property="og:image:height" content="110" />
      {/* 600x315 Image for Facebook */}
      <meta property="og:image:width" content="600" />
      <meta property="og:image:height" content="314" />
      {/* 800x800 Image for Whatsapp */}
      <meta property="og:image:width" content="800" />
      <meta property="og:image:height" content="800" />
      {/* 1080x1080 Image for Line */}
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="twitter:site" content={seoData?.siteName} />
      <meta property="twitter:title" content={seoData?.twitter?.title} />
      <meta property="twitter:description" content={seoData?.twitter?.description} />
      <meta property="twitter:image" content={seoData?.twitter?.image} />
    </Head>
  );
};

export default SEOComponent;
