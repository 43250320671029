import { useEffect, useState } from 'react';
import { CMSTransformerDataType } from '../types/common';
import { attachLivePreviewEvent, removeLivePreviewListener } from './livePreviewService';

function useLivePreview(page: CMSTransformerDataType, seo: CMSTransformerDataType) {
  const [pageData, setPageData] = useState<CMSTransformerDataType>(page);
  const [seoState, setSEOState] = useState<CMSTransformerDataType>(seo);

  useEffect(() => {
    // eslint-disable-next-line
    attachLivePreviewEvent(function (payload: any, contentTypeId: string) {
      if (payload?.data?.length && payload.data[0].entry && contentTypeId) {
        setPageData(payload.data[0].entry);
      }
      if (payload?.data?.length && payload.data[0].entry?.seo_config) {
        setSEOState(payload.data[0].entry?.seo_config);
      }
    });
    // eslint-disable-next-line
    return () => removeLivePreviewListener(() => {});
  }, []);
  useEffect(() => {
    setPageData(page);
    setSEOState(seo);
  }, [page, seo]);

  return [pageData, seoState, setPageData, setSEOState];
}

export default useLivePreview;
